.cluster-marker {
  color: #fff;
  background: #9eb117;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pin-text {
  font-size: 1.3em;
  margin-left: 15px;
  font-weight: bold;
}
